---
title: 'Development tools'
metaTitle: 'Admin-UI Docs | Getting started > Development tools'
metaDescription: 'Find out more about the various debugging tools available to you'
---

## Your browser console

For a front-end developer, the first tool available is your browser's console.
But the easiest way to talk about it is to consult the official documentation for your browsers.
- DevTools for Chrome: [https://developer.chrome.com/docs/devtools](https://developer.chrome.com/docs/devtools)
- DevTools for Firefox: [https://firefox-source-docs.mozilla.org/devtools-user/index.html](https://firefox-source-docs.mozilla.org/devtools-user/index.html)
- DevTools for Edge: [https://learn.microsoft.com/en-us/microsoft-edge/devtools-guide-chromium/landing](https://learn.microsoft.com/en-us/microsoft-edge/devtools-guide-chromium/landing)

## Plugins, good to have in your browser

Your favorite browser also comes with plugins, which is perfect for us.
Here are a few examples linked to our code base.

For Chrome:
- [React Dev Tools](https://chromewebstore.google.com/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi) - Invaluable when debugging React components in the browser
- [Redux Dev Tools](https://chromewebstore.google.com/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd) - Shows various state trees and actions for [Redux](https://redux.js.org/)
- [React Context DevTool](https://chromewebstore.google.com/detail/oddhnidmicpefilikhgeagedibnefkcf) - It's perfect for seeing what's going on in [React's Context](https://react.dev/reference/react/createContext#creating-context)
- [Mokku](https://chromewebstore.google.com/detail/mokku/llflfcikklhgamfmnjkgpdadpmdplmji) - Adds the API mocker MOKKU to Chrome Developer Tools for seamless integration & testing
- [JSON Formatter](https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa) - Makes JSON easy to read
- [WhatFont](https://chromewebstore.google.com/detail/whatfont/jabopobgcpjmedljpbcaablpmlmfcogm) - The easiest way to identify fonts on web pages
- [ColorZilla](https://chromewebstore.google.com/detail/colorzilla/bhlhnicpbhignbdhedgjhgdocnmhomnp) - Advanced Eyedropper, Color Picker, Gradient Generator and other colorful goodies
- [Page Ruler](https://chromewebstore.google.com/detail/page-ruler/jcbmcnpepaddcedmjdcmhbekjhbfnlff) - Measure page elements size in pixel with an easy-to-use ruler
- [Lorem Ipsum Generator](https://chromewebstore.google.com/detail/lorem-ipsum-generator/knpaghjjfkafnfmndphdefcnnijiiham) - Use lorem ipsum generator to create dummy text

For Firefox:
- [React Dev Tools](https://addons.mozilla.org/en-CA/firefox/addon/react-devtools) - Invaluable when debugging React components in the browser
- [Redux Dev Tools](https://addons.mozilla.org/en-CA/firefox/addon/reduxdevtools) - Shows various state trees and actions for [Redux](https://redux.js.org/)
- [React Context DevTool](https://addons.mozilla.org/en-CA/firefox/addon/react-context-devtool) - It's perfect for seeing what's going on in [React's Context](https://react.dev/reference/react/createContext#creating-context)
- [JSON Formatter](https://addons.mozilla.org/en-CA/firefox/addon/json_formatter) - Makes JSON easy to read
- [WhatFont](https://addons.mozilla.org/en-CA/firefox/addon/zjm-whatfont) - The easiest way to identify fonts on web pages
- [ColorZilla](https://addons.mozilla.org/en-CA/firefox/addon/colorzilla) - Advanced Eyedropper, Color Picker, Gradient Generator and other colorful goodies
- [Page Ruler](https://addons.mozilla.org/en-CA/firefox/addon/page-ruler) - Measure page elements size in pixel with an easy-to-use ruler
- [Lorem Ipsum Generator](https://addons.mozilla.org/en-CA/firefox/addon/awesome-lorem-ipsum-generator) - Use lorem ipsum generator to create dummy text

## Our debug bar

The Admin-UI project is delivered with a debug bar available in each application.

### Configure your applications in your local machine

Go to the root of your project from a terminal, then run these commands.

```bash
# for @apps/platform
echo VITE_USE_DEBUG_BAR=true > ./apps/platform/.env.local

# for @apps/commerce-hub
echo VITE_USE_DEBUG_BAR=true > ./apps/commerce-hub/.env.local

# for @apps/knowledge-hub
echo VITE_USE_DEBUG_BAR=true > ./apps/knowledge-hub/.env.local

# for @apps/docs
echo VITE_USE_DEBUG_BAR=true > ./apps/docs/.env.local
```

> Note: Our debug bar is also automatically available in our pull request demos.

### What to find in our debug bar

You'll find your debug bar at the bottom right of your screen, the little Coveo icon.
To open it, simply click on it.

![Debug Bar closed](./images/development-tools-debug-bar-closed.png)

Once open, the debug bar will display the tools on the left and a cross on the right to close it.

![Debug Bar opened](./images/development-tools-debug-bar-opened.png)

#### OwnershipTool

This tool allows you to see who owns the current page and automatically creates a bug for them in their Jira project.
![OwnershipTool](./images/development-tools-debug-bar-OwnershipTool.png)

#### LaunchDarklyTool

This tool lets you see the LaunchDarkly flags of current features and their status.
You can also modify them, but the impact will only be local.

> Note: Not available in Docs application.

The **Summary** section (available by hovering over the button) lets you view the active flags on your organization.
![LaunchDarklyTool Summary](./images/development-tools-debug-bar-LaunchDarklyTool-summary.png)

The **Panel** section (accessible by clicking the button) you can temporarily enable or disable your LaunchDarkly flags.
![LaunchDarklyTool Panel](./images/development-tools-debug-bar-LaunchDarklyTool-panel.png)

#### UIFeatureFlagTool

This tool lets you view the User Interface flags of current functionalities and their status. You can also modify them directly.

> Note: UI Feature Flags are deprecated & available only in the Platform application.

The **Panel** section (accessible by clicking the button) you can temporarily enable or disable your UI flags.
![UIFeatureFlagTool Panel](./images/development-tools-debug-bar-UIFeatureFlagTool-panel.png)

#### TanstackReactQueryTool

The **Panel** section (accessible by clicking the button) displays the DevtoolsPanel component of TanstackReactQuery.
![TanstackReactQueryTool Panel](./images/development-tools-debug-bar-TanstackReactQueryTool-panel.png)

#### TanstackReactRouterTool

> Note: Available only in the Docs application.

The **Panel** section (accessible by clicking the button) displays the DevtoolsPanel component of TanstackReactRouter.
![TanstackReactRouterTool Panel](./images/development-tools-debug-bar-TanstackReactRouterTool-panel.png)

#### TrackingTool

> Note: We keep only the last 25 events of the user.

The **Panel** section (accessible by clicking the button) you can view the data sent to Amplitude.
![TrackingTool](./images/development-tools-debug-bar-TrackingTool-panel.png)

