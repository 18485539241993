---
title: '@core/tracking'
metaTitle: 'Admin UI | Core - Tracking package'
metaDescription: 'How to use Amplitude in your package'
---

Amplitude aims to enhance our understanding of user behavior within the platform by tracking their interactions and engagement. By analyzing this data, we can make informed decisions to improve user experience, identify popular features, and prioritize future developments.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/tracking@*"

or

pnpm add "@core/tracking@*" --recursive --filter <your-package>
```

## User Guide

### track

Send an event to Amplitude along with event properties with the `track` function. `track` is defined like so:

```javascript
track = (event: TrackEvent, data: TrackData = {})
```

It takes a `TrackEvent` as first parameter:
```javascript
type TrackEvent = {
action: ActionType;
subject: string;
};
```

This type has been created to help implementers follow event taxonomy standards as much as possible.
`action` is an `ActionType`, which is a predefined list of allowed actions (viewed, clicked, etc.), and subject is the string that defines the event (ex.: source add).
To learn more about Amplitude event taxonomy and its intricacies, please read the [Amplitude taxonomy documentation](https://coveord.atlassian.net/wiki/spaces/PRODUCT/pages/2898919518/Amplitude+Taxonomy).

Lint rules and Sentry alerts have also been setup to help avoid problematic usage of `TrackEvent` within `track`.

`track` takes a `TrackData` as a second optional parameter:
```javascript
type TrackData = Record<string, any>;
```

This is where you can pass event properties.
Event properties are additional information you'd like to tie to your event that is not included in your event name.
It allows for event generalization, i.e. when we want to trigger the same event in multiple locations but there's context we'd like to add. Here's an example:

```javascript
track({'action': 'completed', 'subject': 'source add'}, {'sourceType': 'WEB2', 'sourceId': 'abcd123'})
```
There's no validation for event properties, you can send whichever information you'd like as long as it's under the `Record<string, any>` format.

> Note: The **track** method must only be used in a `tracking` folder at the root of your package's `src` folder.
This rule is mainly related to code review.

### trackClickOnCoveoDocumentation

Contains a simplified function for tracking documentation clicks.

> Note: Only used in our applications.

### trackPageView

Handles the logic of using `pages-mapping` to send page views to Amplitude based on routing.

> Note: Only used in our applications.

### pages-mapping files

A folder that contains a `route: eventName` mapping for every relevant route of the platform.
It is structured around main subroutes, so there's a file for every subroute (ex.: activity.ts, home.ts, content.ts, etc.).
To add a page view based on routing, you just need to add an entry in one of the existing
files or create a new one if the subroute is not present.

If you want to generalize a page view event and send a subpath as an event property because its dynamic (ex.: organizationId), you can do it like so:

```javascript
'/:organizationId/content/extensions/:extensionId': 'viewed extension edit'
```

`trackPageView` will then call `track` like so:
```javascript
track({'action': 'viewed', 'subject': 'extension edit'}, {organizationId, extensionId})
```
