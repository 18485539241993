---
title: 'Getting started'
metaTitle: 'Admin-UI Docs | Getting started'
metaDescription: 'A quick starting guide on navigating Admin-UI and its file structure'
---

A quick starting guide on navigating in Admin-UI and its file structure.
Here we will cover our tools and technologies being used for development.

## Overview

The Admin-UI project is a front-end project that brings together the various Coveo product administrations (Platform, Commerce Hub, Knowledge Hub).

This front-end project mainly uses [ViteJs](https://vitejs.dev), [Turbo](https://turbo.build/repo/docs), [React](https://react.dev), [Mantine](https://mantine.dev) (via our [Plasma](https://plasma.coveo.com) public library),
[Tanstack React Query](https://tanstack.com/query/latest/docs/framework/react/overview) (for our API calls), [Platform Client](https://github.com/coveo/platform-client) (our official public library for communicating with the Coveo Cloud APIs).
For testing, we mainly use [Jest](https://jestjs.io), [React Testing Library](https://testing-library.com/docs/react-testing-library/intro) and [Playwright](https://playwright.dev) for our end-to-end tests.

## Folder Structure

We will take a quick glance at the folder structure and how it relates to Admin-UI project.

> Please note that this is subject to change in the future as we are currently re-working the structure of the Admin-UI folder structure.
> You can find more information and details in this [ADR-001](https://github.com/coveo-platform/admin-ui/blob/master/docs/adr/001-root-project-architecture.md) & [ADR-005](https://github.com/coveo-platform/admin-ui/blob/master/docs/adr/005-multiple-applications-configuration-architecture.md)

Working in our Admin-UI repo, you will see a structure like so, which highlights the relevant files you will be working on

```
├── apps
│   ├── commerce-hub
│   ├── docs
│   ├── knowledge-hub
│   └── platform
├── components
│   ├── charts
│   ├── mantine
│   ├── navigation
│   ├── page
│   ├── security
│   └── ...
├── configurations
│   ├── commerce-hub
│   |     └── ...
│   ├── knowledge-hub
│   |     └── ...
│   └── platform
│         └── src
│             ├── paths
│             ├── feature-flags
│             ├── tracking
│             └── ...
├── core
│   ├── api
│   ├── authentication
│   ├── feature-flag
│   ├── locales
│   ├── organization
│   └── ...
├── docs
│   └── adr
├── e2e
│   └── ...
├── packages
│   ├── jsadmin-commerce
│   ├── jsadmin-common
│   ├── jsadmin-log-browser
│   ├── jsadmin-oauth-popup
│   ├── jsadmin-search-optimization
│   ├── jsadmin-service
│   ├── jsadmin-usage-analytics
│   ├── qpl-converters
│   └── v2
└── pages
    ├── activity-browser
    ├── home
    ├── source
    └── ...
```

To better understand this structure and the implications between each type of package, here's a pyramid of inverted dependencies.

```
------------------------------------
 \ Dependencies (React, Plasma..) /
  --------------------------------
   \           @core            /
    ----------------------------
     \       @components      /
      ------------------------
       \  @configurations   /
        --------------------
         \     @pages     /
          ----------------
           \  @coveord  / # deprecated packages from the `packages` folder
            ------------
             \ @apps  /
              --------
```

**@core** folder

This folder contains our reusable tools. These tools are the basis of the Admin-UI project.

**@components** folder

This folder contains packages that represent an independent unit of logic that renders something visual somewhere,
which should not be tied to a separate section of navigation and must work in every application.

**@configurations** folder

This folder contains packages that represent the configuration of each of our applications to leave the **@core** and **@components** packages agnostic of our applications.

**@pages** folder

This folder contains packages that render some visual portion of the application but linked at a business code precise i.e a “page”.
The big difference that we could have with the **@components** packages is that here we will only have business code linked to a section of the project navigation.

**@coveord** folder

This folder contains the old deprecated code base.
You'll certainly have to work in it, which is fine, but the main objective is to remove everything from here by rewriting the code to the new packages and, at the same time, removing our technical debt.

**@apps**

This folder contains the contents of each of our applications without business code.

**e2e**

Is our folder containing all our end-to-end tests set up with Playwright.

## Technical debt and continuous migration

Our legacy code, which is mainly in the `/packages` folder, contains Backbone.js and uses a framework called Marionette.js.
Currently, React and Backbone coexist in its packages.

**First continuous migration plan**

The team decided that future code and functionality would be developed using React.
So in order to migrate, we're using Backbone to render React components initially.
We will eventually reduce the Backbone code in the future by refactoring the old Backbone components to fit the new functionality.

Backbone has a very different approach to web development compared to React;
it uses an MVC (model-view-controller) approach to web design where as React is a UI rendering library that takes care of the view layer and does not have a central controller.

**Second continuous migration plan** (current plan)

To simplify migration and improve our speed, we now create smaller packages and rewrite our functionalities directly with the latest technologies like Mantine.
We've also decided to stop using technologies like Redux, which are very verbose and bring a certain complexity.
